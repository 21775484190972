@use '@angular/material' as mat;

$primary-palette: (
  50 : #000000,
  100 : #262626,
  200 : #303030,
  300 : #666666,
  400 : #aaaaaa,
  500 : #ffffff,
  600 : #f0f0f0,
  700 : #222222,
  800 : #000000,
  900 : #000000,
  A100 : #ffffff,
  A200 : #ffffff,
  A400 : #ffffff,
  A700 : #ffffff,
  contrast: (
    50 : #ffffff,
    100 : #ffffff,
    200 : #ffffff,
    300 : #ffffff,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000
  )
);

$accent-palette: (
  50 : #c2c2c2,
  100 : #c2c2c2,
  200 : #ebebeb,
  300 : #ffffff,
  400 : #ffffff,
  500 : #222222,
  600 : #000000,
  700 : #ebebeb,
  800 : #c2c2c2,
  900 : #c2c2c2,
  A100 : #ffffff,
  A200 : #ffffff,
  A400 : #ffffff,
  A700 : #ffffff,
  contrast: (
    50 : #222222,
    100 : #222222,
    200 : #222222,
    300 : #222222,
    400 : #222222,
    500 : #ffffff,
    600 : #ffffff,
    700 : #222222,
    800 : #222222,
    900 : #222222,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000
  )
);

$warn-palette: (
  50 : #990000,
  100 : #dc3333,
  200 : #ff6666,
  300 : #ffffff,
  400 : #ffffff,
  500 : #ffffff,
  600 : #ffffff,
  700 : #990000,
  800 : #990000,
  900 : #990000,
  A100 : #ff7576,
  A200 : #ff8f8f,
  A400 : #ffc2c2,
  A700 : #fff5f5,
  contrast: (
    50 : #ffffff,
    100 : #ffffff,
    200 : #ffffff,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000
  )
);

$app-primary-palette: mat.define-palette($primary-palette, 700, 200, 800, 500);
$app-accent-palette: mat.define-palette($accent-palette, 200, 200, 800, 500);
$app-warn-palette: mat.define-palette($warn-palette, 100, 50, 700, 500);

$app-primary-color: map-get($primary-palette, 700);
$app-accent-color: map-get($accent-palette, 200);
$app-warn-color: map-get($warn-palette, 100);

$app-th-bg-color: #d8d8d8;
$app-disabled-tab-label-color: #606060;
$app-selected-color: #66666670;
$app-selected-color-dark: #66666670;