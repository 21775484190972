@use '@angular/material' as mat;
@include mat.core();
@import "colors";

$app-light-theme: mat.define-light-theme((
  color: (
    primary: $app-primary-palette,
    accent: $app-accent-palette,
    warn: $app-warn-palette
  )
));

$app-dark-theme: mat.define-dark-theme((
  color: (
    primary: $app-accent-palette,
    accent: $app-primary-palette,
    warn: $app-warn-palette
  )
));

.color-scheme-light {
  @include mat.all-component-colors($app-light-theme);
}

.color-scheme-dark {
  @include mat.all-component-colors($app-dark-theme);
}

.color-scheme-light, .color-scheme-dark{
  .mat-mdc-slide-toggle.mat-mdc-slide-toggle-checked:not(.mat-disabled) .mdc-switch__shadow {
    background-color: $app-warn-color;
  };
  .mat-mdc-slide-toggle.mat-mdc-slide-toggle-checked:not(.mat-disabled) .mdc-switch__track::after {
    background-color:$app-warn-color !important;
  };
  .mat-mdc-tab-header-pagination-disabled,
  .mat-mdc-tab-header-pagination-before,
  .mat-mdc-tab-header-pagination-after{
    background-color: $app-primary-color;
  };
  .mat-mdc-tab-header-pagination-chevron{
    border-color:$app-th-bg-color
  };

 mat-snack-bar-container{
    .mdc-snackbar__surface {
      background-color: $app-primary-color;
    }
    .mdc-snackbar__label{
      color: $app-accent-color;
    }
    .mdc-button__label{
      color: $app-accent-color;
    }
  }

} 