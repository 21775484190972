@import "~leaflet/dist/leaflet.css";
@import "~leaflet-draw/dist/leaflet.draw.css";
@import './theme.scss';
@import '../node_modules/ngx-guided-tour/scss/guided-tour-base-theme.scss';
@import "./assets/scripts/leaflet-side-by-side-modified/layout.css";
@import "./assets/scripts/leaflet-side-by-side-modified/range.css";
@import '../node_modules/@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';


html, body {
  height: 100%;
}

* {
  box-sizing: border-box;
  margin: 0;
}
.half-height{
  height: 80vh;
  width: auto;
  max-width: 100%;
}


.black-logo {
  background-image: url("assets/logos/pantera_head.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 100%;
  width:100%;
}

.negative-logo {
  background-image: url("assets/logos/pantera_head_negative.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 100%;
  width: 100%;
}

.full-negative-logo {
  background-image: url("assets/logos/VerticalNegativada_s_borda.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 100%;
  width: 100%;
}

.negative-tipo {
  background-image: url("assets/logos/tipoNegativado.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 100%;
  width: 100%;
}

.table-row {
  cursor: pointer;

  mat-icon {
    font-size: 24px;
  }
}

.map-side-panel{
  width: 100%;
  height: 100%;
}
/*FLEX*/
.flex {
  display: flex;
}

.fx-column {
  display: flex;
  flex-direction: column;
}

.fx-row-center{
  display: flex;
  align-items: center;
  justify-content: center;
}

.fx-col-center{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.fx-space-around{
  justify-content: space-around;
}

.fx-row-space-around-center {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.fx-column-space-around-center {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.fx-row-space-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fx-row-space-around-start {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}

/*LEAFLET*/

.red-svg{
  filter: invert(25%) sepia(98%) saturate(4917%) hue-rotate(357deg) brightness(95%) contrast(109%);
}

.orange-svg {
  filter: invert(69%) sepia(98%) saturate(1718%) hue-rotate(-12deg) brightness(101%) contrast(101%);
}

.amber-svg {
  filter: invert(83%) sepia(84%) saturate(641%) hue-rotate(357deg) brightness(103%) contrast(101%);
}

.blue-svg{
  filter: invert(9%) sepia(78%) saturate(7483%) hue-rotate(247deg) brightness(107%) contrast(149%);
}

.grey-svg {
  filter: invert(57%) sepia(3%) saturate(10%) hue-rotate(331deg) brightness(88%) contrast(77%) opacity(80%);
}

.black-svg {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(0%) contrast(100%);
}

.leaflet-popup-content-wrapper{
  overflow: hidden;
  background: rgb(129 129 129);
}

.leaflet-popup-content{
  margin:3px;
}

.leaflet-popup-content > div {
  padding-right: 1rem;
}

.leaflet-container a.leaflet-popup-close-button {
  color:#000000;
  padding: 5px 20px 0px 0px;
  width: 13px;
  height: 9px;}

.map-legend {
  padding: 6px 8px;
  font: 14px/16px Arial, Helvetica, sans-serif;
  background: rgba(255,255,255,0.8);
  box-shadow: 0 0 15px rgba(0,0,0,0.2);
  border-radius: 5px;
  line-height: 18px;
  color: #555;
  div{
      display: flex;
      align-items: center;
  }
  i {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      float: left;
      margin-right: 8px;
      opacity: 0.7;
  }
}

.marker-label{
  border-radius: 999px;
  background-color: #D3D3D3;
  border: 1px solid #3A3A3A;
  padding: 2px 4px;
  font-size: 10px;
}

.talhao-label {
  font-size: 10px;
  font-weight: bold;
  color: black;
  text-align: center;
  background: none;
  border: none;
  box-shadow: none;
  margin: 0;
  padding: 0;
  max-width: 20px;
  white-space: pre-wrap;
}

.internet{
  background-color: rgb(107, 200, 231);
}

/*CUSTOM MATERIAL*/

.tab-icon {
  margin-right: 8px;
}

.custom-toolbar{
  height: 3rem;
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
}

.my-fab {
  width: 24px !important;
  height: 24px !important;
  line-height: 14px !important;
  font-size: 14px !important;
  
  .mat-mdc-button-persistent-ripple{
    line-height: 14px !important;
    padding: 0 !important;
 }

  .mat-icon {
      font-size: 14px !important;
      padding-top: 6px !important;
  }
}

.cancel-button {
  font-size: 13px !important;
  padding-top: 6px !important;
}

mat-card {
  box-shadow: 0 2px 1px -1px #0003, 0 1px 1px #00000024, 0 1px 3px #0000001f !important;
  padding: 1rem;
}

.custom-dialog-container .mat-mdc-dialog-container {
  padding: 1rem !important;
}

th.mat-mdc-header-cell{
  font-size: 13px;
  font-weight: bold;
}

/*Map Legend layers*/
.img-map-legend {
  background: white;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

.circle-map-legend {
  position: relative;
  bottom:20px;
  line-height: 25px;
  color: black;
  border: 1px solid #555;
  
  background: rgba(255,255,255,0.93);
  box-shadow: 0 0 15px rgba(0,0,0,0.2);
  border-radius: 5px;

  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 4px;
}

.circle-map-legend i {
  border-radius: 50px;
  display: inline-block;
  vertical-align: middle;
  width: 18px;
  height: 18px;
  margin-right: 6px;
  opacity: 1;
  border: 0.5px solid black;
}

.square-map-legend {
  max-height: 400px;
  overflow: auto;

  position: relative;
  bottom:20px;
  line-height: 25px;
  color: black;
  border: 1px solid #555;
  
  background: rgba(255,255,255,0.93);
  box-shadow: 0 0 15px rgba(0,0,0,0.2);
  border-radius: 5px;

  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 4px;
}

.square-map-legend i {
  display: inline-block;
  vertical-align: middle;
  width: 18px;
  height: 18px;
  margin-right: 6px;
  opacity: 1;
  border: 0.5px solid black;
}

.prodes-legend {
  height: 400px;
  overflow-y: auto;  
}

/*Guided tour onboarding*/

ngx-guided-tour .tour-step .tour-title {
  padding-bottom: 10px !important;
  margin: 0 !important;
}
ngx-guided-tour .tour-block {
  padding: 12px 9px !important;
}
ngx-guided-tour .tour-step .tour-content {
  min-height: 50px !important;
  padding-bottom: 10px !important;
}

.pweb-video-thumbnail {
  max-width: 360px !important;
}

@media (max-width: 600px){
  .pweb-video-thumbnail {
    max-width: 200px !important;
}
ngx-guided-tour .tour-block {
  width: 220px !important;
}
}

/*Cameras dialog*/

.app-full-bleed-dialog .mat-mdc-dialog-container {
  padding: 4px;
  width: 100vw !important;
}
/* scroll bar*/

::-webkit-scrollbar {
  width: 9px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Thumb color on hover */
}

.dark-mode-hyperlink{
  color: #1da0ab;
}

.mdc-snackbar--open, .mdc-snackbar__label{
  visibility: visible;
  display: flex !important;
  justify-content: center !important;
}